<template>
  <div id="subpage-content">
    <CompanyDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.Leadership") }}</h1>
        <div id="partn-cards">
          <v-col v-for="item in leads" :key="item.id" :cols="item.id">
          <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              v-on:click="saveCurrentItem(item)"
              @click="$router.push('/company/workpages/anyworker')"
            >
              <img :src="getImageUrl(item)" href="" id="work-pic" />
              <div id="partn-card-title">{{ getItemName(item) }}</div>
              <div id="partn-card-title">{{ getItemJob(item) }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space"> {{ getItemEmail(item) }}</a>
                </div>
                <div>
                  <v-icon>mdi-phone</v-icon>

                  <a id="space">{{ getItemPhone(item) }}</a>
                </div>
              </div>
            </v-card>
          </v-hover>
          </v-col>
          <!-- <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              @click="$router.push('/company/workpages/director')"
            >
              <img :src="getImageUrlbyName('Dir')" href="" id="work-pic" />
              <div id="partn-card-title">{{ $t("message.FIO1") }}</div>
              <div id="partn-card-title">{{ $t("message.JobTitle1") }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space">lada@kg.ru</a>
                </div>
                <div>
                  <v-icon>mdi-phone</v-icon>

                  <a id="space">+7 (846) 219-26-28</a>
                </div>
              </div>
            </v-card>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              @click="$router.push('/company/workpages/findir')"
            >
              <img
                :src="getImageUrlbyName('Findir')"
                href=""
                id="work-pic"
              />
              <div id="partn-card-title">{{ $t("message.FIO2") }}</div>
              <div id="partn-card-title">{{ $t("message.JobTitle2") }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space">ak@kg.ru</a>
                </div>
                <div>
                  <v-icon>mdi-phone</v-icon>

                  <a id="space">+7 (902) 372-66-22</a>
                </div>
              </div>
            </v-card>
          </v-hover> -->
        </div>
        <h1 id="naming">{{ $t("message.Devs") }}</h1>
        <div id="partn-cards">
          <v-col v-for="item in works" :key="item.id" :cols="item.id">
          <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              v-on:click="saveCurrentItem(item)"
              @click="$router.push('/company/workpages/anyworker')"
            >
              <img :src="getImageUrl(item)" href="" id="work-pic" />
              <div id="partn-card-title">{{ getItemName(item) }}</div>
              <div id="partn-card-title">{{ getItemJob(item) }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space"> {{ getItemEmail(item) }}</a>
                </div>
                <div>
                  <!-- <v-icon>mdi-phone</v-icon> -->

                  <a id="space">{{ getItemPhone(item) }}</a>
                </div>
              </div>
            </v-card>
          </v-hover>
          </v-col>
          <!-- <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              @click="$router.push('/company/workpages/suhorukhov')"
            >
              <img :src="getImageUrlbyName('Wr1')" href="" id="work-pic" />
              <div id="partn-card-title">{{ $t("message.FIO3") }}</div>
              <div id="partn-card-title">{{ $t("message.JobTitle3") }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space">suhorukov@kg.ru</a>
                </div>
              </div>
            </v-card>
          </v-hover>
          <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              @click="$router.push('/company/workpages/goretski')"
            >
              <img :src="getImageUrlbyName('Wr2')" href="" id="work-pic" />
              <div id="partn-card-title">{{ $t("message.FIO4") }}</div>
              <div id="partn-card-title">{{ $t("message.JobTitle4") }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space">goretskiy@kg.ru</a>
                </div>
              </div>
            </v-card>
          </v-hover>

          <v-hover v-slot="{ hover }">
            <v-card
              width="250"
              height="450"
              id="serv-card"
              :elevation="hover ? 12 : 2"
              @click="$router.push('/company/workpages/shapilov')"
            >
              <img :src="getImageUrlbyName('Wr3')" href="" id="work-pic" />
              <div id="partn-card-title">{{ $t("message.FIO5") }}</div>
              <div id="partn-card-title">{{ $t("message.JobTitle5") }}</div>
              <div id="partn-card-text">
                <div id="rowc">
                  <v-icon>mdi-email</v-icon>

                  <a id="space">shapilov@kg.ru</a>
                </div>
              </div>
            </v-card>
          </v-hover> -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<style>
#partn-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
}
#partn-card-title {
  display: flex;
  text-align: center;
  align-self: center;
}
#partn-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
#rowc {
  display: flex;
  align-items: center;
}
#space {
  margin: 5px;
}
#work-pic {
  margin: 30px;
}
</style>

<script>
import CompanyDrawer from "../../../views/Navigations/Company/CompanyDrawer";
export default {
  components: {
    CompanyDrawer,
  },
  data: () => ({
    leads: [],
    works: [],
  }),
  async mounted() {
    await this.getWorkblockLead();
    await this.getWorkblockWork();
  },
  methods: {
    async getWorkblockLead() {
      await this.$store.dispatch("getAllWorkblocks");
      this.leads = await this.$store.getters.getWorkblocks.filter(item => item.islead);
    },
    async getWorkblockWork() {
      await this.$store.dispatch("getAllWorkblocks");
      this.works = await this.$store.getters.getWorkblocks.filter(item => item.iswork);
    },
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item.image;
    },
    getItemName(item) {
      if (this.$i18n.locale === "ru") {
        return item.name2;
      } else if (this.$i18n.locale === "gb") {
        return item.name1;
      } else if (this.$i18n.locale === "de") {
        return item.name3;
      }
    },
    getItemText(item) {
      if (this.$i18n.locale === "ru") {
        return item.shorttext2;
      } else if (this.$i18n.locale === "gb") {
        return item.shorttext1;
      } else if (this.$i18n.locale === "de") {
        return item.shorttext3;
      }
    },
    getItemJob(item) {
      if (this.$i18n.locale === "ru") {
        return item.job2;
      } else if (this.$i18n.locale === "gb") {
        return item.job1;
      } else if (this.$i18n.locale === "de") {
        return item.job3;
      }
    },
    getItemEmail(item) {
      return item.email
    },
    getItemPhone(item) {
      return item.phone
    },
    saveCurrentItem(item) {
      localStorage.itemid = item.id;
      localStorage.itemimage= item.image;
      localStorage.itemname1 = item.name1;
      localStorage.itemname2 = item.name2;
      localStorage.itemname3 = item.name3;
      localStorage.itemtext1 = item.text1;
      localStorage.itemtext2 = item.text2;
      localStorage.itemtext3 = item.text3;
      localStorage.itemjob1 = item.job1;
      localStorage.itemjob2 = item.job2;
      localStorage.itemjob3 = item.job3;
      localStorage.itememail = item.email;
      localStorage.itemphone = item.phone;
      localStorage.itemislead = item.islead;
      return null;
    },
  },
  watch: {
    itemdata: function (newValue) {
      if (newValue) {
        localStorage.item = newValue;
      }
    },
  },
};
</script>
